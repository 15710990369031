import Player from '@vimeo/player';
jQuery.noConflict();

/**
 * Vimeo Slider
 **/
class VimeoPlayer {
	constructor() {
		//console.log('VimeoPlayer:constructor');
		const _this = this;
		window.addEventListener('anlDocReady', function (e) {
			_this.onReady(e);
		});
	}

	onReady(event) {
		//console.log('VimeoPlayer::onReady', this, event);
		// Initialize Project Vimeo Videos
		const $players = $('.project-player').not('.initialized');
		
		$players.each((i, el)=> {
			//console.log('found a player!');

			const $playerEl = $(el).find('.player'),
						vimeoID = $playerEl.data('vimeo-id'),
						$loadingIcon = $(el).find('.loading-icon'),
						$playIcon = $(el).find('.play-icon'),
						$cover = $(el).find('.cover'),
						playerOptions = {
							id: vimeoID,
							title: false,
							byline: false,
							portrait: false,
							autoplay: false,
							responsive: true,
						};

			//console.log($playerEl, playerOptions);

			const player = new Player($playerEl, playerOptions);

			player.on('ended', (e) => {
				$cover.show();
				player.pause();
				player.setCurrentTime(0);
			});
			player.ready().then(function() {
				//console.log('Vimeo Video Player is ready!');
				$loadingIcon.hide();
				$playIcon.show();

				$($cover).on('click', ()=> {
					$cover.hide();
					player.play();
				})
			});

			$(el).addClass('initialized');
				
		});
	}
}

const vimeoPlayer = window.vimeoPlayer || new VimeoPlayer();
window.vimeoPlayer = vimeoPlayer;

export default vimeoPlayer;
